@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family:'Cera Pro';
  font-weight:400;
  src:url(/static/media/CeraPro.76103000.ttf);
}
@font-face {
  font-family:'Cera Pro';
  font-weight:500;
  src:url(/static/media/CeraProMedium.2bc02ae8.otf);
}
@font-face {
  font-family:'Cera Pro';
  font-weight:600;
  src:url(/static/media/CeraProBold.23dd9a3e.otf);
}

:root {
  --side-nav-width: 250px;

  --white: white;
  --light-grey: #f8f8f8;
  --dark-grey: #777777;
  --black: black;

  --accent: #2fbe5f;
  --accent-light: #2fbe5f25;
  --accent-dark: #30aa59;

  --notification-warning: #ffd900;
  --notification-warning-light:rgba(255, 217, 0, 0.2);

  --light-yellow: rgba(235, 212, 0, 0.1);
  --light-yellow-text: #ffbb00;

  /* --subject-math: ;
  --subject-math-light: ;
  
  --subject-chemsitry: ;
  --subject-chemsitry-light: ;
  
  --subject-physics: ;
  --subject-physics-light: ;
  
  --subject-biology: ;
  --subject-biology-light: ; */
}

* {
  margin:0;
  padding:0;
}

::selection {
  background:rgba(0, 0, 0, 0.2);
}

body {
  font-family:'Cera Pro', sans-serif;
  background:#f8f8f8;
  background:var(--light-grey);
  height:100%;
}

h3 {
  display:table;
  margin-top:30px;
  padding:20px 30px;
  font-weight:500;
  font-size:18px;
  color:#777777;
  color:var(--dark-grey);
}

/* Side Nav Styles */

.side-nav {
  width:250px;
  width:var(--side-nav-width);
  height:100vh;
  background:white;
  background:var(--white);
  display:flex;
  flex-direction: column;
  flex-grow:0;
  left:0;
  position:fixed;
  transition:0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow:hidden;
}

.hide-nav {
  padding:23px 30px;
  cursor:pointer;
}

.open-nav {
  position:fixed;
  bottom:20px;
  left:15px;
  padding: 15px;
  height:25px;
  
  /* background:rgba(255, 255, 255, 0.425);
  backdrop-filter: blur(20px); */
  background:white;

  cursor:pointer;
  font-family:var(--font-header);
  font-weight:500;
  border-radius:50px;
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
  z-index: 9000;
  transition:0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);


  align-items:center;
  justify-content:center;
  box-shadow: 0 0 10px rgba(0,0,0,0.12);

  transform:translateX(210px);
  
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  /* display:none; */
}

.open-nav img {
  height:25px;
  width:25px;
  transform:rotate(0deg);

}

/* closed */
.side-nav.sliderfalse {
  transform:translateX(-100%);
  width:0;
}

.open-nav.openedfalse {
  display:flex;
  transform:rotate(180deg);
}

.side-nav {
  justify-content:space-between;
}

.side-nav .subjects,
.side-nav .actions {
  display:flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
}

.side-nav .subjects {
  padding-bottom:15px;
  overflow-y:auto;
  overflow-x: hidden;
}

.side-nav .actions {
  padding-bottom:15px;
}

.header-link,
.subject-link,
.logout,
.hide-nav {
  text-decoration:none;
  color:black;
  color:var(--black);
  box-sizing: border-box;
  font-weight:500;
  transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.subject-link.disabled {
  cursor:not-allowed;
}

.headmast {
  width:100%;
}

.header-link {
  width:100%;
  display:table;
  padding: 30px 30px;
}

.subject-link {
  display:table;
  padding:15px 30px;
}

.logout {
  display:table;
  padding:20px 30px;
}

.header-link:hover,
.subject-link:hover,
.logout:hover,
.hide-nav:hover {
  background:#2fbe5f25;
  background:var(--accent-light);
}

.side-nav .active {
  background:#2fbe5f25;
  background:var(--accent-light);
  border-style:none solid none none;
  border-width:5px;
  border-color:#2fbe5f;
  border-color:var(--accent);
}


.wrapper {
  /* display:flex; */
  margin-left:250px;
  margin-left:var(--side-nav-width);
  transition:0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
}

.syllabus {
  flex-grow:1;
  box-sizing: border-box;
  padding:75px;
  transition:0.3s padding cubic-bezier(0.13, 0.31, 0.15, 0.93);
}

.syllabus .header {
  display:flex;
  justify-content: space-between;
  height:40px;
  align-items:center;
}

.syllabus .header h1 {
  font-weight:500;
  font-size:24px;
}

.syllabus h3 {
  padding-left: 0;
  padding-bottom: 0;
}

.syllabus .header button {
  border:none;
  background:#777;
  color:white;
  color:var(--white);
  box-sizing: border-box;
  padding:10px 15px;
  border-radius:5px;
  font-size:15px;
  cursor:pointer;
}

.syllabus .exam-info table {
  box-sizing: border-box;
  padding:30px 0;
  max-width:500px;
}

.syllabus .exam-info table .tr {
  display:flex;
}

.syllabus .exam-info table td,
.syllabus .exam-info table th {
  padding:10px 15px 0 0;
  font-weight:500;
  text-align: left;
}

.syllabus .exam-info table th {
  color: #777777;
  color: var(--dark-grey);
  padding-right:75px;
}

.syllabus .topic-container {
  box-sizing: border-box;
  display:flex;
  flex-direction:column;
}

.syllabus .topic-container .topic {
  width:100%;
  background:white;
  background:var(--white);
  box-sizing: border-box;
  padding:20px;
  border-radius:5px;
  box-shadow:0 0 10px rgba(0,0,0,0.01);
  text-decoration:none;
  color:black;
  color:var(--black);
  font-weight:500;
  display:flex;
  align-items:center;
  transition:all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  margin-top:25px;
}

.syllabus .topic-container .topic:hover {
  box-shadow:3px 3px 7px rgba(0,0,0,0.05);
}

.topic .topic-no {
  background:#2fbe5f25;
  background:var(--accent-light);
  border-radius:100%;
  width:35px;
  height:35px;
  display:flex;
  color:#2fbe5f;
  color:var(--accent);
  line-height:35px;
  justify-content:center;
  font-size:20px;
  margin-right:20px;
  flex-shrink:0;
}

.topic-container.AHL .topic-no {
  background:rgba(235, 212, 0, 0.1);
  background:var(--light-yellow);
  color:#ffbb00;
  color:var(--light-yellow-text);
}

.topic-container.options .topic-no {
  background:rgba(41, 204, 188, 0.1);
  color:#29CCBC;
}

.topic .topic-name {
  font-size:20px;
  font-weight:500;
  padding-bottom:5px;
  flex-grow:1;
}

.topic .topic-subs {
  font-family:'Open Sans', sans-serif;
  color:#777;
  display:flex;
  overflow-x: auto;
  max-width:75%;
}

.topic .topic-subs .sub-tag {
  background:#f8f8f8;
  background:var(--light-grey);
  font-weight:400;
  margin-left:10px;
  box-sizing: border-box;
  padding:5px 7px;
  font-family:'Open Sans', sans-serif;
  border-radius:5px;
  font-size:15px;
  height:100%;
  width:auto;
}



.topic-page,
.question-page {
  flex-grow:1;
  box-sizing: border-box;
  padding:60px;
  /* transition:0.3s padding cubic-bezier(0.13, 0.31, 0.15, 0.93); */
}

.topic-page .header {
  display:flex;
  justify-content:space-between;
  align-items: center;
  /* transition: padding 0.2s cubic-bezier(0.075, 0.82, 0.165, 1); */
  
  top:0;
  position:fixed;
  width:calc(100% - 250px);
  width:calc(100% - var(--side-nav-width));
  left:250px;
  left:var(--side-nav-width);
  box-sizing: border-box;
  padding:75px;
}

.question-page .header {
  display:flex;
  justify-content:space-between;
  align-items: center;
  transition: padding 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.header-actions {
  z-index:2001;
  position:fixed;
  right:30px;
  top:40px;
  /* transition: transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1); */
}

.topic-page.sticky .header {
  background:rgba(255, 255, 255, 0.425);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  z-index: 2000;
  box-sizing: border-box;
  padding:30px 30px;
  border-style:none none none solid;
  border-width:1px;
  border-color:#eee;
  box-shadow: 0 0 10px rgba(0,0,0,0.01);
}

.topic-page .header .breadcrumb {
  display:flex;
  align-items:center;
  font-size:20px;
  font-weight:500;
  color:#777;
  height:60px;
  max-width:calc(100% - 568px);
}

.question-page .header .breadcrumb {
  display:flex;
  align-items:center;
  font-size:20px;
  font-weight:500;
  color:#777;
  height:60px;
}

.topic-page .slash {
  padding-right:10px;
}

.topic-page .header .breadcrumb .breadcrumb-link,
.question-page .header .breadcrumb .breadcrumb-link {
  display:flex;
  align-items:center;
  text-decoration:none;
  color:#777;
}

.question-page .header .breadcrumb .breadcrumb-link {
  margin-right:10px;
}

.topic-page .header .breadcrumb .breadcrumb-topic,
.question-page .header .breadcrumb .breadcrumb-topic {
  margin-left:10px;
  color:black;
  color:var(--black);
}

.topic-page .header .breadcrumb .breadcrumb-question,
.question-page .header .breadcrumb .breadcrumb-question {
  margin-left:10px;
  color:black;
  display:flex;
}

.topic-page .header .breadcrumb .breadcrumb-link .breadcrumb-subject {
  white-space: nowrap;
  width:auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.topic-page .header .breadcrumb .breadcrumb-topic {
  white-space: nowrap;
  width:auto;
  overflow: hidden;
  text-overflow: ellipsis;
}


.topic-page .paper-buttons,
.question-page .paper-buttons {
  display:flex;
  align-items: center;
  font-weight:500;
}

.topic-page .paper-buttons a {
  color:#2fbe5f;
  color:var(--accent);
  background:#2fbe5f25;
  background:var(--accent-light);
  text-decoration: none;
  padding:10px 17px;
  border-radius:4px;
  transition:0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

@media only screen and (min-width: 1200px) {
  .topic-page .paper-buttons a:hover {
    color:white !important;
    background:#2fbe5f !important;
    background:var(--accent) !important;
  }
}

.topic-page .paper-buttons a.active {
  color:white !important;
  background:#2fbe5f !important;
  background:var(--accent) !important;
}

.topic-page .paper-buttons a.disabled, .topic-page .paper-buttons a.disabled:hover {
  color: #777777!important;
  color: var(--dark-grey)!important;
  background:#eee !important;
}

.topic-page .paper-buttons a.paper-2, a.paper-3 {
  margin-left:20px;
}

.subject-page header {
  height:100px;
  position:default;
}

.questions-container {
  padding-top:40px;
}

.questions-container h3,
.question-container h3 {
  padding-left:0;
}

.question-out, .markscheme-container {
  overflow-x: auto;
}

.questions-container .questions {
  background:white;
  background:var(--white);
  overflow:hidden;
  border-radius:5px;
  background:white;
  background:var(--white);
  box-shadow:0 0 10px rgba(0,0,0,0.01);
}

.questions .question-item {
  box-sizing: border-box;
  padding:20px;
  display:flex;
  font-weight:500;
  color:#222;
  text-decoration:none;

  border-style:none none solid none;
  border-width:1px;
  border-color:#eee;
  
  /* background:rgba(0, 235, 0, 0.10); */
}

.questions .question-item:last-child {
  border-style:none;
}

.questions .question-item .question-id {
  color:#777;
  width: 115px;
  display: inline-table;
}

.questions .question-item .question-name {
  flex-grow:1;
  padding-left:20px;
  box-sizing: border-box;
}

.questions .question-item .question-name {
  font-size: 1rem;
  font-family: 'Open Sans';
}


.questions .question-item.complete {
  background:rgba(39, 235, 0, 0.1);
}

.questions .question-item.revisit {
  background:rgba(235, 212, 0, 0.1);
  background:var(--light-yellow);
}


.breadcrumb-question .q-tag {
  margin-left:10px;
  font-weight:500;
  color:white;
  font-size:16px;
  padding:2px 5px;
  border-radius:4px;

  display:none;
} .breadcrumb-question .q-tag.revisit {
  background:#2fbe5f;
  background:var(--accent);
} .breadcrumb-question .q-tag.complete {
  background:green;
}

.question-page.revisit .q-tag.revisit {
  display:table;
} .question-page.complete .q-tag.complete {
  display:table;
}

.markscheme-toggle {
  width:100%;
  display:flex;
  justify-content:center;
  margin: 35px 0;
  height: 44px;
}

.markscheme-toggle .toggle {
  display:flex;
  align-items:center;
  cursor:pointer;
  font-weight:500;
  font-size:15px;
  box-sizing: border-box;
  padding:5px 0;
  color:#777;
  font-family: 'Open Sans', sans-serif;
  padding:10px 15px;
  border-radius:5px;
  font-weight:bold;
  transition:0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.markscheme-toggle .toggle:hover {
  background:#eee;
}

.markscheme-toggle .toggle img {
  margin-right:10px;
}

.action {
  display:flex;
  justify-content: center;
  margin-top:50px;
}

.action button {
  border:none;
  padding:10px 15px;
  font-size:15px;
  margin-left:20px;
  cursor:pointer;
  color:white;
  border-radius:5px;
  font-family:'Open Sans', sans-serif;
  font-weight:bold;
}

.action button.complete-button {
  background:#2eca6d;
  margin-left: 0;
}

.action button.revisit-button {
  background:#fb6000;
}

.action button.complete-button.unmark {
  background:#858585;
}

.action button.revisit-button.unmark {
  background:#858585;
}

.action button.skip-button {
  background:#0a94d4;
}

.action button.skip-button.disabledtrue {
  background:#222;
  color:#777;
}

.question-out,
.markscheme-container {
  background:white;
  border-radius:5px;
  padding:20px;
}





/* Dashboard Temp Style */

.main-message {
  width:100%;
  height:100vh;
  font-weight:500;
  font-size:18px;
  display:flex;
  justify-content:center;
  align-items:center;
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
}

.main-message .logout {
  padding:0;
  margin-left:10px;
  color:#CCC;
  font-size:18px;
  font-weight:500;
}

.main-message .logout:hover {
  background:none;
  color:#777;
}

.main-message .embroidery {
  color:#CCC;
}

::-webkit-scrollbar {
  height: 7px;
  width:7px;
  background:#eee;
}

::-webkit-scrollbar-thumb {
  background:#CCC;
  cursor:pointer;
  border-radius:4px;
}

.header-actions {
  display:flex;
  justify-content:flex-end;
  flex-grow:1;
}

.search-box {
  /* margin-top:50px; */
  /* padding:20px; */
  /* background:white; */
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.01);
  display:flex;
}

.search-box .search-icon {
  display:flex;
  align-items:center;
  justify-content:center;
  padding:0 10px;
  border-radius:4px 0 0 4px;
  margin-right:-40px;
  z-index:1;
}

.search-box .search-icon .icon {
  height:20px;
  width:20px;
}

.search-box .search-input {
  display:flex;
  justify-content:flex-end;
}

.search-box h3 {
  padding-left:0;
  padding-top:0;
  margin-top:0;
}

.search-box input[type="text"] {
  border:none;
  border-radius:4px;
  background:#eee;
  padding:9px 15px 9px 40px;
  outline:none;
  font-size:16px;
  flex-grow:1;
  font-family:'Open Sans', sans-serif;
}

.search-box input[type="text"]:hover,
.search-box input[type="text"]:focus {
  background:rgb(224, 224, 224);
}

.search-box input[type="submit"] {
  border:none;
  border-radius:4px;
  background:#eee;
  padding:10px 15px;
  outline:none;
  font-size:16px;
  cursor:pointer;
  margin-left:20px;
}

.search-box input[type="submit"]:hover {
  background:#ccc;
}

.no-ques {
  padding:20px;
  color: #777777;
  color: var(--dark-grey);
  font-weight: 500;
}




/* Landing page styles */

.landing-wrapper {
  padding-bottom:100px;
}

.landing-wrapper .landing {
  width:1000px;
  margin:0 auto;
}

.landing-wrapper .head {
  width:100%;
  display:flex;
  height:100px;
  align-items:center;
  justify-content: space-between;
  box-sizing: border-box;
}

.landing-wrapper .head .head-text {
  font-size:1.25rem;
  color:#CCC;
  font-weight:500;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.landing-wrapper .head .head-link .login-button,
.landing-wrapper .login .google,
.landing-wrapper .redirect-button {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor:pointer;
  text-decoration:none;
  color:black;
  background:#2fbe5f25;
  background:var(--accent-light);
  padding:10px 15px;
  border-radius:4px;
  color:#2fbe5f;
  color:var(--accent);
  font-weight:500;
  transition:0.13s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.landing-wrapper .head .head-link .login-button:hover,
.landing-wrapper .login .google:hover,
.landing-wrapper .redirect-button:hover {
  background:#30aa59;
  background:var(--accent-dark);
  color:white;
}

.landing-wrapper .login .google.disabledtrue {
  background: black;
  color:rgb(116, 116, 116);
  -webkit-user-select:none;
  -ms-user-select: none;
      user-select: none;
}

.blackpink {
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  min-height:500px;
  box-sizing: border-box;
  padding:125px 0;
}

.blackpink h1 {
  font-weight:500;
  text-align: center;
  padding-bottom:25px;
  font-size:2.75rem;
  text-transform: lowercase;
}

.blackpink h1 i {
  padding-right:5px;
}

.blackpink .image-container {
  box-sizing: border-box;
  padding:35px 0 25px 0;
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
}

.blackpink img {
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
  box-shadow: 0 0 10px rgba(0,0,0,0.05);
  max-width: 100%;
}

.landing-wrapper h2 {
  text-align:center;
  padding-bottom:75px;
  color:#CCC;
  font-weight:500;
  font-size:1.7rem;
}

.landing-wrapper .features {
  display:flex;
  padding:0 0 100px 0;
  box-sizing: border-box;
  justify-content:space-around;
}

.landing-wrapper .features .item {
  width:32%;
  text-align: center;
}

.landing-wrapper .features .item img {
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
  border-radius:100%;
  padding:10px;
  margin-bottom:20px;
}

.landing-wrapper .features .item img.save {
  background:#2fbe5f25;
  background:var(--accent-light);
} .landing-wrapper .features .item img.layout {
  background:rgba(255, 217, 0, 0.2);
  background:var(--notification-warning-light);
} .landing-wrapper .features .item img.zap {
  background:rgba(41, 204, 188, 0.1);
}

.landing-wrapper .features .item h4 {
  font-weight:500;
  font-size:1.2rem;
  color:#777;
}

.landing-wrapper .features .item p {
  padding-top:10px;
  font-family:'Open Sans', sans-serif;
  line-height:30px;
}

.landing-wrapper .footer {
  padding:35px 0;
  text-align:center;
  color:#777;
}

.landing-wrapper .footer a {
  text-decoration:none;
  color:#777;
  transition:0.13s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.landing-wrapper .footer a:hover {
  color:#CCC;
}

.landing-wrapper .login {
  text-align:center;
  margin-bottom:50px;
}

.landing-wrapper .login .google,
.landing-wrapper .redirect-button {
  outline:none;
  border:none;
  font-size:18px;
  font-family:var(--font-header);
}

.landing-wrapper .login .login-error {
  font-weight:500;
  color:#ffbb00;
  color:var(--light-yellow-text);
  display:table;
  margin:15px auto 0 auto;
  box-sizing: border-box;
}

h2#start {
  padding-top:50px;
}

.cta {
  padding-bottom:50px;
}

.killed-message {
  box-sizing: border-box;
  padding:20px;
  color:#ffbb00;
  color:var(--light-yellow-text);
  background:rgba(235, 212, 0, 0.1);
  background:var(--light-yellow);
  border-radius:4px;
  font-weight:bold;
  display:table;
  font-size:1.17rem;
  margin:0 auto;
}

.killed-message .killed-message-inner {
  display:flex;
  align-items:center;
}

.killed-message .killed-message-inner svg {
  margin-right:10px;
}



@media only screen and (max-width: 1200px) {

  .topic-page .header .breadcrumb {
    max-width:calc(100% - 475px);
  }

  .topic-page .paper-buttons a {
    margin-left: 5px !important;
    padding-left: 10px;
    padding-right: 10px;
  }

  .search-box {
    padding-right:5px;
  }

}

@media only screen and (max-width: 1000px) {

  .question-page {
    padding:20px;
  }

  .topic-page .slash {
    display:none;
  }


  /* landing page re-styles */
  .landing-wrapper .landing {
    width:100%;
    box-sizing: border-box;
    padding:0 20px;
  }
  
  .landing-wrapper .features {
    flex-direction:column;
    padding-bottom:50px;
  }
  
  .landing-wrapper .features .item {
    width:100%;
    padding-top:50px;
  }
  
  .landing-wrapper .features .item:first-child {
    padding-top:0;
  }
  
  .landing-wrapper .image-container {
    display:none;
  }
  
  .landing-wrapper h2#start{
    padding-bottom: 30px;
  }
  
  /* minimize padding on side to maximize screen space */
  .topic-page,
  .question-page {
    padding: 75px 20px;
    width: 100vw;

    /*  No transition because the sticky header is supposed to snap on seamlessly -- but HEADER has transition */
    transition:none;
  }

  /* TOPIC-PAGE IS ALWAYS STICKY */
  
  .topic-page.sticky {
    /* padding-top:150px; */
    padding-top: 90px;
  }

  /* when header is not sticky */
  .topic-page .header {
    padding:0;
    flex-direction:column;
    align-items:flex-start;
    height:110px;
    width:100%;
  }

  .topic-page .header .breadcrumb,
  .question-page .header .breadcrumb {
    max-width:100%;
  }

  .topic-page .header .breadcrumb {
    height: 60px;
  }

  .topic-page .header-actions {
    transform:translateY(30px);
    transition: none;
    width:100%;
    left:-13px;
    right:0;
    padding-right:7px;
    box-sizing: border-box;
  }

  /* when header is sticky */
  .topic-page.sticky .header {
    padding:5px 10px 10px 10px;
  }

  .header-actions {
    top:30px;
  }

  .search-box {
    left:-10px;
  }
  
  .topic-page.sticky .search-box {
    top:110px;
    width:calc(100% - 165px);
    box-sizing: border-box;
  }
  
  .topic-page.sticky .search-box .search-input {
    width:100%;
  }

  .open-nav.openedtrue ~ .topic-page.sticky .header-actions {
    left:250px !important;
    left:var(--side-nav-width) !important;
  }
  
  /* left arrow on the breadcrumb */
  .topic-page .header a.breadcrumb-link img,
  .question-page .header a.breadcrumb-link img {
    padding:5px;
    box-sizing: border-box;
  } 
  .topic-page .header .breadcrumb .breadcrumb-link,
  .question-page .header .breadcrumb .breadcrumb-link {
    margin-right:0;
    padding-top: 2px;
  }
  /* topic text on the breadcrumb */
  .topic-page .header .breadcrumb-link .breadcrumb-subject,
  .question-page .header .breadcrumb-link .breadcrumb-subject {
    display:none;
  }
  .topic-page .header .breadcrumb-topic, .breadcrumb-question,
  .question-page .header .breadcrumb-topic, .breadcrumb-question {
    font-size:1.05rem;
  }

  .questions-container {
    padding-top:10px;
  }
  
  .topic-page .header .header-actions {
    width:100%;
    justify-content: flex-end;
  }
  .topic-page .header .breadcrumb {
    width:100%;
    padding-bottom:10px;
  }

  .search-input input[type="text"] {
    width:100%  !important;
  }

  .question-page .question-container h3 {
    margin-top:0;
  }
  
  .search-box {
    padding:0;
  }
  .search-box .search-icon {
    padding-right: 5px;
    padding-left:5px;
    margin-left: 0;
    margin-right: -35px;
  }

  .search-box input[type="text"] {
    padding-left: 35px; 
    padding-right: 0px;
  }

  .syllabus {
    padding: 75px 20px;
    width: 100vw;
  }
  

  .syllabus .exam-info table th {
    color: #777777;
    color: var(--dark-grey);
    padding-right:25px;
  }


  .questions .question-item {
    flex-direction:column;
  }
  
  .questions .question-item .question-name {
    padding-left:0;
    padding-top:8px;
  }

  .question-out,
  .markscheme-container {
    padding:15px;
  }

  /* Prevent jumpy sliding on page load on mobile (since side nav would be hidden by default) */
  :root {
    --side-nav-width: 0px;
  }
}
.question-container .question {
    padding-left: 20px;
}

.specification {
    margin-bottom: 2em;
}

.question, .specification {
    position: relative;
    display:block;
    padding-left: 20px;
    padding-right: 20px;
}

.question p, .specification p {
    min-height: 30px;
    line-height: 30px!important;
    font-size: inherit!important;
}

@media only screen and (max-width: 900px) {
    .question p, .specification p {
        margin-bottom: 5px;
    }    
}
/* Math questions use span */
.question, .specification, .question span, .specification span {
    font-family: "Open Sans", helvetica neue,Arial,lucida grande,lucida sans unicode,sans-serif !important;
    font-size: 16px !important;
    margin: 0 0 1.5em 0px;
}

.marks {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    text-align: right;
    height: 30px;
    line-height: 30px;
}

.question .marks, .specification .marks {
    color: #aaa;
}

.question .question_part_label, .specification .question_part_label {
    position: absolute;
    left: 0;
    top: 0;
    height: 30px;
    line-height: 30px;
}


.markscheme-container h2 {
    padding:20px;
}

.question-container img, .markscheme-container img {
    height: auto !important;
    width: auto !important;
    max-height: 80vh;
    max-width: 100%;
    margin: 20px 0;
}

.question-container table, .markscheme-container table {
    margin: 20px auto 0 auto !important;
    width: 100% !important;
    max-width: 500px;
    padding-left: 0 !important;
}

.question-container table td, .markscheme-container table td {
    margin: 0 !important;
    padding: 0 !important;
}
