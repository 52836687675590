.question-container .question {
    padding-left: 20px;
}

.specification {
    margin-bottom: 2em;
}

.question, .specification {
    position: relative;
    display:block;
    padding-left: 20px;
    padding-right: 20px;
}

.question p, .specification p {
    min-height: 30px;
    line-height: 30px!important;
    font-size: inherit!important;
}

@media only screen and (max-width: 900px) {
    .question p, .specification p {
        margin-bottom: 5px;
    }    
}
/* Math questions use span */
.question, .specification, .question span, .specification span {
    font-family: "Open Sans", helvetica neue,Arial,lucida grande,lucida sans unicode,sans-serif !important;
    font-size: 16px !important;
    margin: 0 0 1.5em 0px;
}

.marks {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    text-align: right;
    height: 30px;
    line-height: 30px;
}

.question .marks, .specification .marks {
    color: #aaa;
}

.question .question_part_label, .specification .question_part_label {
    position: absolute;
    left: 0;
    top: 0;
    height: 30px;
    line-height: 30px;
}


.markscheme-container h2 {
    padding:20px;
}

.question-container img, .markscheme-container img {
    height: auto !important;
    width: auto !important;
    max-height: 80vh;
    max-width: 100%;
    margin: 20px 0;
}

.question-container table, .markscheme-container table {
    margin: 20px auto 0 auto !important;
    width: 100% !important;
    max-width: 500px;
    padding-left: 0 !important;
}

.question-container table td, .markscheme-container table td {
    margin: 0 !important;
    padding: 0 !important;
}